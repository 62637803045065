import React from 'react';
import Form from '../components/form';

export default function SignUp() {
  return <div>
      <Form data={
        {"email":""}
      }></Form>
  </div>;
}
